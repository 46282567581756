;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.crm')
    .controller('CRMBookingController', CRMBookingController)
    .controller('CRMBookingListController', CRMBookingListController)
    .controller('CRMBookingNewController', CRMBookingNewController)

  function CRMBookingController($scope, $transitions) {
    var deregistrators = []
    $scope.$on('$destroy', () => deregistrators.forEach((fn) => fn()))

    deregistrators.push(
      $transitions.onSuccess({ to: 'crm.bookings.**' }, function (transition) {
        transition.noStatFilterReset = true
      })
    )
  }

  function CRMBookingListController($filter, $mdDialog, $scope, $state, $transition$, CRMBookingService) {
    var ctrl = this
    ctrl.delete = deleteBooking
    ctrl.export = exportBookings
    init()

    function init() {
      if (!$transition$.params().store) {
        $state.go('^')
        return
      }
      load()
    }

    function load() {
      ctrl.dates = [$scope.filter.dates.from, $scope.filter.dates.to]

      var params = {
        store: $scope.filter.storeId,
        dispatchDate: [$filter('date')(ctrl.dates[0]), $filter('date')(ctrl.dates[1])],
      }

      ctrl.loading = true
      CRMBookingService.getBookings(params)
        .then((response) => {
          ctrl.countStores = new Set(response.map((booking) => booking.store.storeId)).size
          ctrl.countBookings = response.length
          ctrl.data = prepareBookings(response)
        })
        .finally(() => {
          ctrl.loading = false
        })
    }

    function prepareBookings(bookings) {
      return Object.values(
        bookings.reduce((collection, booking) => {
          if (!collection[booking.store.storeId]) {
            collection[booking.store.storeId] = {
              store: booking.store,
              bookings: [],
            }
          }
          booking.closingDateTime = getClosingDateTime(
            moment(booking.dispatchDate),
            booking.campaign.settings.closingTime
          )
          collection[booking.store.storeId].bookings.push(booking)
          return collection
        }, {})
      )
    }

    function deleteBooking(event, list, booking) {
      if (!booking.cancelable || !booking.closingDateTime.isAfter()) {
        return
      }

      var confirm = $mdDialog
        .confirm()
        .title('Buchung löschen')
        .htmlContent(
          'Möchten Sie die Buchung von <b><q>' +
            booking.campaign.title +
            '</q></b> für den <b>' +
            $filter('date')(booking.dispatchDate) +
            '</b> löschen?'
        )
        .ok('Ja, löschen')
        .cancel('Nein, abbrechen')
        .targetEvent(event)

      confirm._options.focusOnOpen = false

      $mdDialog.show(confirm).then(() => {
        booking.$delete().then(() => {
          var index = list.indexOf(booking)
          if (index !== -1) {
            list.splice(index, 1)
          }
        })
      })
    }

    function exportBookings(event) {
      if (ctrl.loading) {
        return
      }

      var params = {
        store: $scope.filter.storeId,
        dispatchDate: [$filter('date')(ctrl.dates[0]), $filter('date')(ctrl.dates[1])],
      }

      ctrl.loading = true
      CRMBookingService.export(params).finally(() => {
        ctrl.loading = false
      })
    }
  }

  function CRMBookingNewController(
    $filter,
    $q,
    $scope,
    $timeout,
    $transition$,
    CRMBookingService,
    CRMCampaignService,
    UserService
  ) {
    var ctrl = this
    ctrl.steps = ['Typ', 'Kampagne', 'Datum', 'Übersicht']
    ctrl.goto = goto
    ctrl.restart = restart
    ctrl.isGodMode = false
    ctrl.canGodMode = UserService.hasRole(['admin', 'storesall'])
    ctrl.godMode = godMode
    init()

    function goto(event, step) {
      ctrl.step = step
      ctrl.proceed = false
      ctrl.stepTemplateUrl = 'src/crm/views/bookings.new.' + (step + 1) + '.html'
      if ($scope['step' + (step + 1)] && angular.isFunction($scope['step' + (step + 1)].init)) {
        $scope['step' + (step + 1)].init()
      }
    }

    function init() {
      if ($transition$.params().store) {
        ctrl.store = $transition$.params().store
        load()
      } else {
        var deregister = $scope.$watch('filter.store', (store) => {
          if (store) {
            ctrl.store = store[0] || store
            deregister()
            load()
          }
        })
      }
    }

    function load() {
      ctrl.customer = ctrl.store.customer

      ctrl.loading = true
      $q.all([
        CRMCampaignService.getSettingsList({ customer: ctrl.customer.customerId }),
        CRMCampaignService.getTypeList({ customer: ctrl.customer.customerId }),
        CRMCampaignService.getCampaigns({ customer: ctrl.customer.customerId }),
        CRMBookingService.getSubscribersCount(ctrl.store),
      ])
        .then((response) => {
          ctrl.settingsList = response[0]
          ctrl.typeList = response[1]
          ctrl.allCampaigns = response[2]
          ctrl.campaigns = filterCampaigns(response[2])
          ctrl.subscribers = response[3]

          ctrl.maxAdvanceDuration = ctrl.campaigns.reduce(
            (max, campaign) => Math.max(max, campaign.settings.advanceDuration),
            0
          )

          return restart()
        })
        .finally(() => {
          ctrl.loading = false
        })
    }

    function filterCampaigns(campaigns) {
      var now = moment()
      return campaigns.filter((campaign) => {
        var startDate = campaign.startDate ? moment(campaign.startDate) : null
        var endDate = campaign.endDate ? moment(campaign.endDate) : null
        var advanceDuration = campaign.settings.advanceDuration || 0
        if (!ctrl.isGodMode && startDate && now.isBefore(startDate.clone().subtract(advanceDuration, 'days'), 'day')) {
          return false
        }
        if (endDate && now.isAfter(endDate, 'day')) {
          return false
        }
        return true
      })
    }

    function restart(event, type) {
      return loadBookings().then(() => {
        if (type) {
          $scope.step1.select(event, type)
          goto(event, 1)
          return
        }

        goto(event, 0)
      })
    }

    function loadBookings() {
      var params = {
        store: ctrl.store.storeId,
        dispatchDate: [
          moment(Math.min(moment().startOf('month'), moment().startOf('week'))).format('L'),
          moment().add(ctrl.maxAdvanceDuration, 'days').endOf('week').format('L'),
        ],
      }
      return CRMBookingService.getBookings(params).then((response) => {
        ctrl.bookings = response
        ctrl.calendar = createCalendar(ctrl.maxAdvanceDuration)
      })
    }

    function godMode(event) {
      if (!ctrl.canGodMode) {
        return
      }
      ctrl.isGodMode = true
      ctrl.campaigns = filterCampaigns(ctrl.allCampaigns)
    }

    $scope.step1 = {
      init: function () {
        delete this.abbr
        delete this.type
        delete this.infos

        this.notices = ctrl.typeList.reduce((notices, type) => {
          if (!type.isBookable && !type.hidden) {
            if (type.hitLimit) {
              notices.push(
                'Es können aktuell keine ' +
                  type.title +
                  ' mehr gebucht werden, da ein Limit erreicht wurde.<br>' +
                  'Unter <q>Buchungen anzeigen</q> können Sie bereits gebuchte Kampagnen löschen.'
              )
            } else {
              notices.push('Es können aktuell keine ' + type.title + ' gebucht werden.')
            }
          }
          return notices
        }, [])
      },
      select: function (event, type) {
        if (!type.isBookable && !ctrl.isGodMode) {
          return
        }
        this.abbr = abbreviate(type.title)
        this.type = type

        var infos = $filter('orderBy')(ctrl.campaigns, 'title')
          .filter((campaign) => campaign.type.id == type.id)
          .reduce((infos, campaign) => {
            var campaignInfos = createCampaignInfos(campaign)

            if (!infos) {
              return campaignInfos
            }

            if (infos.advanceDuration != campaignInfos.advanceDuration) {
              delete infos.advanceDuration
            }

            if (infos.bookableWeekdays != campaignInfos.bookableWeekdays) {
              var regex = /^Versand (a[mn] )(.*?)( im Monat)$/
              var oldMatch = infos.bookableWeekdays.match(regex)
              var newMatch = campaignInfos.bookableWeekdays.match(regex)
              if (!oldMatch[2].includes(newMatch[2])) {
                infos.bookableWeekdays = 'Versand ' + oldMatch[1] + oldMatch[2] + ' bzw. ' + newMatch[2] + newMatch[3]
              }
            }

            if (infos.closingTime != campaignInfos.closingTime) {
              delete infos.closingTime
            }

            if (infos.limitPerDay != campaignInfos.limitPerDay) {
              if (!angular.isArray(infos.limitPerDay)) {
                infos.limitPerDay = [infos.limitPerDay].filter(Boolean)
              }
              if (campaignInfos.limitPerDay) {
                infos.limitPerDay.push(campaignInfos.limitPerDay)
              }
            }
            if (infos.limitPerWeek != campaignInfos.limitPerWeek) {
              if (!angular.isArray(infos.limitPerWeek)) {
                infos.limitPerWeek = [infos.limitPerWeek].filter(Boolean)
              }
              if (campaignInfos.limitPerWeek) {
                infos.limitPerWeek.push(campaignInfos.limitPerWeek)
              }
            }
            if (infos.limitPerMonth != campaignInfos.limitPerMonth) {
              if (!angular.isArray(infos.limitPerMonth)) {
                infos.limitPerMonth = [infos.limitPerMonth].filter(Boolean)
              }
              if (campaignInfos.limitPerMonth) {
                infos.limitPerMonth.push(campaignInfos.limitPerMonth)
              }
            }

            if (
              infos.couponValidity &&
              campaignInfos.couponValidity &&
              infos.couponValidity != campaignInfos.couponValidity
            ) {
              delete infos.couponValidity
            }

            return infos
          }, null)

        this.infos = Object.values(infos).reduce((infos, info) => {
          if (angular.isArray(info)) {
            Array.prototype.push.apply(infos, info)
          } else {
            infos.push(info)
          }
          return infos
        }, [])
        ctrl.proceed = true
      },
    }

    $scope.step2 = {
      init: function () {
        delete this.campaign
        delete this.dispatchMethods
        delete this.infos
      },
      selectCampaign: function (event, campaign) {
        if (!campaign.isBookable && !ctrl.isGodMode) {
          return
        }
        this.campaign = campaign
        updateCalendar(ctrl.calendar, campaign)
        this.infos = Object.values(createCampaignInfos(campaign))

        if (campaign.dispatchMethods.length == 1) {
          this.selectDispatchMethods(event, campaign.dispatchMethods)
          return
        }
        delete this.dispatchMethods
        ctrl.proceed = false
      },
      selectDispatchMethods: function (event, dispatchMethods) {
        if (dispatchMethods) {
          this.dispatchMethods = dispatchMethods
        }
        ctrl.proceed = true
      },
    }

    $scope.step3 = {
      init: function () {
        this.reset()
      },
      select: function (event, day) {
        if (!day.isBookable) {
          return
        }
        this.reset()
        day.isSelected = true
        this.day = day
        this.closingDateTime = getClosingDateTime(day.moment, $scope.step2.campaign.settings.closingTime)
        ctrl.proceed = true
      },
      fromDate: function (date) {
        var now = moment()
        var currentDay = moment(date)
        this.select(undefined, {
          date: currentDay.toDate(),
          moment: currentDay.clone(),
          isPast: currentDay.isSameOrBefore(now, 'day'),
          isToday: currentDay.isSame(now, 'day'),
          isBookable: true,
        })
      },
      godModeFilter: function (date) {
        var campaign = angular.copy($scope.step2.campaign)
        campaign.settings.advanceDuration = null
        var day = { moment: moment(date) }
        return isValidWeeday(campaign, day) && isValidDate(campaign, day)
      },
      reset: function () {
        if (this.day) {
          delete this.day.isSelected
          delete this.day
          delete this.closingDateTime
          delete this.godModeDispatch
        }
      },
    }

    $scope.step4 = {
      init: function () {
        delete this.notCancelable
        delete this.allStores

        $timeout(() => {
          ctrl.proceed = true
        }, 2500)
      },
    }

    $scope.step5 = {
      init: function () {
        delete this.success
        delete this.warning

        var params
        if (ctrl.isGodMode) {
          params = {
            cancelable: !Boolean($scope.step4.notCancelable),
            allStores: Boolean($scope.step4.allStores),
          }
        }

        this.loading = true
        CRMBookingService.book(
          $scope.step2.campaign,
          ctrl.store,
          $scope.step3.day.date,
          $scope.step2.dispatchMethods,
          params
        )
          .then((response) => {
            $scope.step5.success = response
          })
          .catch((response) => {
            $scope.step5.warning = {
              message: Object.keys(response.data.messages).reduce((warning, msgKey) => {
                if (msgKey.startsWith('booking')) {
                  warning = (warning ? warning + ' ' : '') + response.data.messages[msgKey]
                }
                return warning
              }, null),
            }
          })
          .finally(() => {
            $scope.step5.loading = false
          })
      },
    }

    function createCalendar(advanceDuration) {
      var now = moment()
      var calendar = []

      var bookings = ctrl.bookings.reduce((collection, booking) => {
        booking.abbr = abbreviate(booking.campaign.type.title)
        var day = moment(booking.dispatchDate).format(moment.HTML5_FMT.DATE)
        if (!collection[day]) {
          collection[day] = [booking]
        } else {
          collection[day].push(booking)
        }
        return collection
      }, {})

      var currentDay = now.clone().add(1, 'day').startOf('week')
      while (advanceDuration > 0) {
        var week = []
        for (var i = 0; i < 7; i++, currentDay.add(1, 'day')) {
          var day = {
            date: currentDay.toDate(),
            moment: currentDay.clone(),
            isPast: currentDay.isSameOrBefore(now, 'day'),
            isToday: currentDay.isSame(now, 'day'),
          }
          if (advanceDuration && currentDay.isAfter()) {
            day.isBookable = true
            advanceDuration -= 1
          }
          if (bookings[currentDay.format(moment.HTML5_FMT.DATE)]) {
            day.bookings = bookings[currentDay.format(moment.HTML5_FMT.DATE)]
          }
          week.push(day)
        }
        calendar.push(week)
      }

      checkBookableCampaigns(calendar)

      return calendar
    }

    function updateCalendar(calendar, campaign) {
      var countBookableDays = 0
      var hitLimit = false

      calendar.forEach((week) => {
        week.forEach((day) => {
          // skip past or non-advance-able days
          if (!Object.hasOwn(day, 'isBookable')) {
            return
          }

          day.isBookable = true

          if (day.isBookable && !isValidWeeday(campaign, day)) {
            day.isBookable = false
          }
          if (day.isBookable && !isValidDate(campaign, day)) {
            day.isBookable = false
          }
          if (day.isBookable && !isValidLimits(campaign, day)) {
            day.isBookable = false
            hitLimit = true
          }

          if (day.isBookable) {
            countBookableDays += 1
          }
        })
      })

      campaign.hitLimit = countBookableDays == 0 && hitLimit

      return countBookableDays
    }

    function createCampaignInfos(campaign) {
      var settings = campaign.settings
      var infos = {}

      if (settings.advanceDuration) {
        var duration = settings.advanceDuration + ' Tag' + (settings.advanceDuration != 1 ? 'e' : '')
        if (settings.advanceDuration % 7 == 0) {
          duration = settings.advanceDuration / 7 + ' Woche' + (settings.advanceDuration / 7 != 1 ? 'n' : '')
        }
        infos.advanceDuration = 'kann bis max. ' + duration + ' vor dem Versand gebucht werden'
      }

      if (settings.bookableWeekdays.length == 0) {
        infos.bookableWeekdays = 'Versand an allen Wochentagen möglich'
      } else {
        var ordinals = ['1.', '2.', '3.', '4.', '5.']
        var bookableWeekdays = {}
        settings.bookableWeekdays.forEach((weekdayCont) => {
          var weekday = moment()
            .startOf('week')
            .add(weekdayCont - 1, 'days')
            .format('dddd')
          if (!Object.hasOwn(bookableWeekdays, weekday)) {
            bookableWeekdays[weekday] = []
          }
          bookableWeekdays[weekday].push(ordinals[Math.ceil(weekdayCont / 7) - 1])
        })
        bookableWeekdays = Object.keys(bookableWeekdays).map(
          (weekday) => join(bookableWeekdays[weekday], ', ', ' oder ') + ' ' + weekday
        )
        bookableWeekdays = join(bookableWeekdays, ', ', ' oder ')
        infos.bookableWeekdays = 'Versand am ' + bookableWeekdays + ' im Monat'
      }

      if (settings.closingTime) {
        infos.closingTime = 'Buchungs-Deadline ist am Vortag bis ' + moment(settings.closingTime).format('HH:mm [Uhr]')
      } else {
        infos.closingTime = 'Buchungs-Deadline ist Mitternacht (00:00 Uhr)'
      }

      var title = settings.blockedBy.length ? 'Kampagne' : settings.title
      var titlePl = settings.blockedBy.length ? 'Kampagnen' : settings.title
      var pluralize = (count, noun, nounPl) => count + ' ' + (count == 1 ? noun : nounPl)
      if (settings.limitPerDay) {
        infos.limitPerDay = pluralize(settings.limitPerDay, title, titlePl) + ' pro Tag buchbar'
      }
      if (settings.limitPerWeek) {
        infos.limitPerWeek = pluralize(settings.limitPerWeek, title, titlePl) + ' pro Woche buchbar'
      }
      if (settings.limitPerMonth) {
        infos.limitPerMonth = pluralize(settings.limitPerMonth, title, titlePl) + ' pro Monat buchbar'
      }

      if (campaign.couponSku && settings.couponValidity) {
        var validity = settings.couponValidity + ' Tag' + (settings.couponValidity != 1 ? 'e' : '')
        if (settings.couponValidity % 7 == 0) {
          validity = settings.couponValidity / 7 + ' Woche' + (settings.couponValidity / 7 != 1 ? 'n' : '')
        }
        infos.couponValidity = 'Coupon ist ab Versandtag ' + validity + ' gültig'
      }

      return infos
    }

    function isValidWeeday(campaign, day) {
      if (!campaign.settings.bookableWeekdays.length) {
        return true
      }

      var ordinal = Math.floor((day.moment.format('DD') - 1) / 7)
      var weekday = parseInt(day.moment.format('E'))
      var weekdayCont = ordinal * 7 + weekday

      return campaign.settings.bookableWeekdays.includes(weekdayCont)
    }

    function isValidDate(campaign, day) {
      var now = moment()
      var startDate = campaign.startDate ? moment(campaign.startDate) : null
      var endDate = campaign.endDate ? moment(campaign.endDate) : null

      if (startDate && day.moment.isBefore(startDate)) {
        return false
      }
      if (endDate && day.moment.isAfter(endDate)) {
        return false
      }

      if (campaign.settings.advanceDuration != null) {
        var maxAdvanceDate = now.clone().add(campaign.settings.advanceDuration, 'days')
        if (day.moment.isAfter(maxAdvanceDate)) {
          return false
        }
      }

      var closingDateTime = getClosingDateTime(day.moment, campaign.settings.closingTime)
      if (now.isAfter(closingDateTime)) {
        return false
      }

      return true
    }

    function isValidLimits(campaignOrSettings, day) {
      var settings = campaignOrSettings.settings || campaignOrSettings
      var valid = checkSettingsLimits(settings, day)
      if (valid) {
        valid = valid && checkForeignLimits(settings, day)
      }
      return valid
    }

    function checkSettingsLimits(settings, day) {
      var countDay = 0
      var countWeek = 0
      var countMonth = 0

      ctrl.bookings.forEach((booking) => {
        var bcs = booking.campaign.settings
        var bdd = moment(booking.dispatchDate)
        if (bcs.id == settings.id || settings.blockedBy.includes(bcs.id)) {
          if (bdd.isSame(day.moment, 'day')) {
            countDay += 1
          }
          if (bdd.format(moment.HTML5_FMT.WEEK) == day.moment.format(moment.HTML5_FMT.WEEK)) {
            countWeek += 1
          }
          if (bdd.isSame(day.moment, 'month')) {
            countMonth += 1
          }
        }
      })

      var limitPerDay = settings.limitPerDay || Number.MAX_SAFE_INTEGER
      var limitPerWeek = settings.limitPerWeek || Number.MAX_SAFE_INTEGER
      var limitPerMonth = settings.limitPerMonth || Number.MAX_SAFE_INTEGER

      return countDay < limitPerDay && countWeek < limitPerWeek && countMonth < limitPerMonth
    }

    function checkForeignLimits(settings, day) {
      var valid = true

      ctrl.settingsList.forEach((foreignSettings) => {
        if (!foreignSettings.blockedBy.includes(settings.id)) {
          return
        }
        valid = valid && isValidLimits(foreignSettings, day)
      })

      return valid
    }

    function checkBookableCampaigns(calendar) {
      var countBookableDays = {}
      ctrl.typeList.forEach((type) => {
        var hitLimit = false
        type.isBookable = false
        ctrl.campaigns
          .filter((campaign) => campaign.type.id == type.id)
          .forEach((campaign) => {
            var cacheKey = [
              campaign.settings.id,
              campaign.startDate ? moment(campaign.startDate).format(moment.HTML5_FMT.DATE) : null,
              campaign.endDate ? moment(campaign.endDate).format(moment.HTML5_FMT.DATE) : null,
            ].join('#')
            if (!Object.hasOwn(countBookableDays, cacheKey)) {
              countBookableDays[cacheKey] = updateCalendar(angular.copy(calendar), campaign)
            }
            campaign.isAllowed =
              campaign.stores.length == 0 || campaign.stores.some((store) => store.storeId == ctrl.store.storeId)
            campaign.isBookable = countBookableDays[cacheKey] > 0 && campaign.isAllowed
            if (campaign.isBookable) {
              type.isBookable = true
            }
            if (campaign.hitLimit) {
              hitLimit = true
            }
          })
        type.hitLimit = !type.isBookable && hitLimit
      })
    }
  }

  function getClosingDateTime(datetime, time) {
    datetime = datetime.clone()
    if (time) {
      time = moment(time)
      datetime.subtract(1, 'day').hours(time.hours()).minutes(time.minutes()).seconds(time.seconds())
    }
    return datetime
  }

  function abbreviate(input) {
    return input
      .replace('Newsletter', 'NL')
      .replace(/(?<=\w)newsletter/, '-NL')
      .replace('Push-Nachricht', 'PN')
  }

  function join(array, separator, lastSeparator) {
    var result = ''
    if (array.length >= 2) {
      result += array.slice(0, -1).join(separator) + (lastSeparator || separator)
    }
    if (array.length >= 1) {
      result += array.slice(-1)[0]
    }
    return result
  }
})()
